<template>
  <div>
    <header class="topheader">
      <div class="topnav">
        <div class="nav__data container-fluid">
          <!-- Logo and Home Navigation -->
          <a @click="navigateToHome" class="nav__logo nav__menu">
            <img class="logoStyle" src="../../assets/logo.png" alt="Logo" />
          </a>

          <!-- Mobile Menu Toggle -->
          <div class="nav__toggle" @click="toggleMenu">
            <i class="ri-menu-line nav__burger" v-if="!isMenuVisible"></i>
            <i class="ri-close-line nav__close" v-if="isMenuVisible"></i>
          </div>

          <div class="h-100 d-flex">
            <p v-if="authStore.auth.userType === 'STORE'" class="store-info">
              Store : {{ authStore.auth.storeName }} ({{
                authStore.auth.storeCode
              }})
            </p>
            <!-- Back Button -->
            <div
              class="form-check form-switch align-self-center pointer"
              @click="toggleAlert"
            >
              <i class="ri-toggle-fill ri-xl toggle-icon"></i>
              <label
                class="fw-semibold ms-2 toggle-label"
                for="flexSwitchCheckChecked"
                disabled
              >
                Old Console
              </label>
            </div>

            <!-- Account User Dropdown -->
            <div class="dropdown" ref="userMenuRef" @click="toggleUserMenu">
              <a
                href="#"
                class="user-menu-link d-flex align-items-center gap-3 mx-3 h-100 px-4"
              >
                <!-- User Avatar -->
                <div class="account-user-avatar">
                  <i class="ri-account-circle-fill user-avatar-icon"></i>
                </div>
                <!-- User Info -->
                <div>
                  <span class="user-info">
                    <span class="account-user-name fw-bold">
                      {{ authStore.auth.username }}
                    </span>
                    <span class="account-user-type">{{
                      authStore.auth.userType
                    }}</span>
                  </span>
                </div>
              </a>
              <!-- Dropdown Content (Logout) -->
              <div
                class="dropdown-content"
                :class="{ show: isUserMenuVisible }"
              >
                <!-- Profile Links based on User Type -->
                <router-link
                  v-if="
                    !isEReceiptRoute &&
                    !authStore.auth.roles.includes('EXTERNAL_USERS')
                  "
                  to="/MyProfile"
                >
                  <i class="ri-account-circle-fill me-1"></i>
                  <span class="profile-link"> My Profile</span>
                </router-link>

                <router-link
                  v-if="
                    !isEReceiptRoute &&
                    authStore.auth.userType !== 'TENANT' &&
                    !authStore.auth.roles.includes('EXTERNAL_USERS')
                  "
                  to="/store-Profile"
                >
                  <i class="ri-user-shared-fill ri-1x me-1"></i>
                  <span class="profile-link"> Store Profile</span>
                </router-link>

                <router-link
                  v-if="
                    !isEReceiptRoute && authStore.auth.userType === 'TENANT'
                  "
                  to="/tenant-Profile"
                >
                  <i class="ri-user-shared-fill ri-1x me-1"></i>
                  <span class="profile-link"> Tenant Profile</span>
                </router-link>

                <router-link to="/reset-Password">
                  <i class="ri-rotate-lock-line ri-1x me-2"></i>
                  <span class="profile-link">Reset Password</span>
                </router-link>

                <router-link to="/logout">
                  <i class="ri-logout-box-r-line ri-1x me-2"></i>
                  <span class="profile-link">Logout</span>
                </router-link>

                <hr class="mb-0 mt-0" />

                <!-- E-Receipt Link -->

                <router-link
                  @click="switchToReadReceiptRoutes"
                  to="/rdep-receipt/dashboard"
                  v-if="
                    !isEReceiptRoute &&
                    !authStore.auth.roles.includes('EXTERNAL_USERS')
                  "
                >
                  <i class="ri-file-list-2-line ri-1x me-2"></i>
                  <span class="profile-link">E-Receipt</span>
                </router-link>
                <router-link
                  @click="switchToReadReceiptRoutes"
                  to="/dashboard"
                  v-if="
                    isEReceiptRoute &&
                    !authStore.auth.roles.includes('EXTERNAL_USERS') &&
                    authStore.auth.landingPage == '/'
                  "
                >
                  <i class="ri-home-2-line ri-1x me-2"></i>
                  <span class="profile-link">Pos-console</span>
                </router-link>
              </div>
            </div>
            <!-- End User Dropdown -->
          </div>
        </div>
      </div>
    </header>

    <!-- Sidebar Navigation Menu -->

    <div class="nav__menu" :class="{ 'show-menu': isMenuVisible }">
      <div class="container-fluid">
        <ul class="nav__list">
          <li
            v-for="(item, index) in isEReceiptRoute
              ? readReceiptRoutes
              : menuList"
            :key="index"
            :class="{ submenu__item: item.children }"
          >
            <!-- Single Item -->
            <template v-if="!item.children">
              <router-link
                :to="item.path"
                class="nav__link"
                @click="activeSubmenu = false"
              >
                <div class="text-white">
                  <i class="me-2" :class="item.icon"></i>
                  <span class="nav__label">{{ item.label }}</span>
                </div>
              </router-link>
            </template>
            <!-- Submenu -->
            <template v-else>
              <div
                class="nav__link"
                :class="{ active: activeSubmenu === index }"
                @click="toggleSubmenu(index)"
              >
                <div class="text-white">
                  <i class="me-2" :class="item.icon"></i>
                  <span class="nav__label">{{ item.label }}</span>
                </div>
                <i
                  class="ri-arrow-down-s-line submenu__arrow"
                  :class="{ active: activeSubmenu === index }"
                ></i>
              </div>
              <div
                class="submenu__container"
                :class="{ 'show-submenu': activeSubmenu === index }"
              >
                <ul class="submenu__list">
                  <li
                    v-for="(child, childIndex) in item.children"
                    :key="childIndex"
                  >
                    <router-link
                      :to="child.path"
                      class="submenu__link text-align-center"
                      :class="{ active: $route.path === child.path }"
                      @click="isMenuVisible = false"
                    >
                      <span class="nav__label"> {{ child.label }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <!-- Alert Modal -->
    <div
      class="modal fade alertModalFade"
      tabindex="-1"
      aria-labelledby="imageModalLabel"
      aria-hidden="false"
      ref="AlertModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-body fw-normal">
            Do you want to go back to the older version?
            <div class="float-end d-flex mt-5 gap-2">
              <button
                type="button"
                class="btn btn-primary buttonPrimary"
                @click="redirectToAdmin"
              >
                Yes
              </button>

              <button
                type="button"
                class="btn btn-danger btn-sm buttonDanger"
                data-bs-dimiss="modal"
                @click="close"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "../../store/auth";
import { useDRPermissionStore } from "../../store/helper/DRmenuPaths.js";
import { useDRMenuStore } from "../../store/helper/DRMenuStore";
import bootstrap from "../../assets/bootstrap.bundle.min";

// import { readReceiptRoutes as staticReadReceiptRoutes } from "../../router/router";
// import axios from "axios";
const router = useRouter();
const route = useRoute();
const props = defineProps({
  menuList: {
    type: Array,
    required: true,
  },
});

const authStore = useAuthStore();

const AlertModal = ref(true);

const isMenuVisible = ref(false);
const activeSubmenu = ref(null);
const isUserMenuVisible = ref(false);
const DRmenuStore = useDRMenuStore();
const DRpermissionStore = useDRPermissionStore();
const readReceiptRoutes = ref([]);
const isEReceiptRoute = ref(false);

let userMenuRef = null;

const navigateToHome = () => {
  // if (authStore.auth.landingPage === "rdep-receipt") {
  //   router.push("/rdep-receipt/dashboard");
  // } else {
  //   router.push(authStore.auth.landingPage);
  // }
  // toggleSubmenu();
  window.location.reload();
};

const switchToReadReceiptRoutes = () => {
  if (readReceiptRoutes.value.length === 0) {
    fetchDigiMenuData();
  }
  activeSubmenu.value = null;
};

const toggleAlert = () => {
  const modal = new bootstrap.Modal(AlertModal.value);
  modal.show();
};

const close = () => {
  if (AlertModal.value) {
    const modal = bootstrap.Modal.getInstance(AlertModal.value);
    modal.hide();
  }
};

const redirectToAdmin = () => {
  close();
  const base = window.location.origin;
  let landingPage = authStore.auth.landingPage;
  const currentUrl = window.location.href;

  if (landingPage === "/") {
    if (currentUrl.includes("rdep-receipt")) {
      window.open(`${base}/pos-console/rdep-receipt`);
    } else {
      window.open(`${base}/pos-console/`);
    }
  } else if (landingPage === "rdep-receipt") {
    window.open(`${base}/pos-console/${landingPage}`);
  }
};

watch(
  () => route.fullPath,
  (newFullPath) => {
    isEReceiptRoute.value = newFullPath.includes("/rdep-receipt");
  },
  { immediate: true }
);

// Digital receipt Navbar End

const toggleMenu = () => {
  isMenuVisible.value = !isMenuVisible.value;
};

const toggleSubmenu = (index) => {
  activeSubmenu.value = activeSubmenu.value === index ? null : index;
};

const toggleUserMenu = () => {
  isUserMenuVisible.value = !isUserMenuVisible.value;
};

const closeDropdownOnOutsideClick = (event) => {
  const dropdown = document.querySelector(".dropdown");
  if (isUserMenuVisible.value && dropdown && !dropdown.contains(event.target)) {
    isUserMenuVisible.value = false;
  }
};

const fetchDigiMenuData = async () => {
  try {
    const data = await DRmenuStore.fetchDigiMenuData();
    readReceiptRoutes.value = data;
    DRpermissionStore.updateAllowedDRPaths(data);
  } catch (error) {
    console.error("Error fetching menu data:", error);
  }
};

onMounted(() => {
  userMenuRef = document.getElementById("userMenu");
  document.body.addEventListener("click", closeDropdownOnOutsideClick);
  if (isEReceiptRoute.value) {
    fetchDigiMenuData();
  }
});

onUnmounted(() => {
  document.body.removeEventListener("click", closeDropdownOnOutsideClick);
});
</script>
<style>
:root {
  --header-height: 4.35rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul {
  margin-bottom: 0 !important;
  list-style: none;
}

a {
  text-decoration: none !important;
}

.alertModalFade {
  background: rgba(0, 0, 0, 0.2);
  z-index: 1088 !important;
  transition: opacity 2s all; /* Add smooth transition effect */
}

.topheader {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  z-index: 999;
}
/** topnav contains logo and userMenu */
.topnav {
  height: var(--header-height);
}

/* logo */
.logoStyle {
  height: auto;
  width: 90px;
  cursor: pointer;
  background-color: #ffffff;
}
/* store info */
.store-info {
  margin: auto;
  color: #727cf5; /* Primary color */
  font-weight: bold;
}

/* switch (toggle) button  */
.pointer {
  cursor: pointer !important;
}

.toggle-icon {
  cursor: pointer;
  color: #727cf5; /* Primary color */
  opacity: 1;
}

.toggle-label {
  color: #727cf5 !important;
  cursor: pointer !important;
  margin: 2px 8px 4px auto;
  font-size: 14px;
}

/* user info and  dropdown   */
.user-menu-link {
  background-color: #f9fafc;
  border-radius: 3px;
  text-decoration: none;
}

.user-avatar-icon {
  color: #cccccc;
  font-size: 2.3em;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #98a6ad;
}

.account-user-type {
  font-size: 12px;
  color: #98a6ad;
}

.profile-link {
  font-size: 13px;
}

.dropdown-content.show {
  display: block;
}

/*    nav    */

.buttonPrimary {
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 3px;
  box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5) !important;
  background-color: #6366f1 !important;
  border: #6366f1 !important;
}

.nav__data {
  height: 100%; /** acquire full height in the topbar  */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
}

.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
}

.nav__burger,
.nav__close {
  position: absolute;
  width: max-content;
  height: max-content;
  margin: auto;
  inset: 0;
  font-size: 1.25rem;
  cursor: pointer;
  transition: opacity 0.1s, transform 0.4s;
}

.nav__close {
  opacity: 1;
}

/*  Mobile Device*/

@media screen and (max-width: 1101px) {
  .nav__menu {
    position: absolute;
    left: 0;
    top: 2rem;
    width: 100%;
    height: calc(100vh);
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    transition: top 0.4s, opacity 0.3s;
    background-color: #313a46;
  }

  .nav__menu ::-webkit-scrollbar {
    display: none;
  }

  .nav__list {
    font-weight: 200 !important;
    height: 100vh;
    background-color: #313a46;
    padding-top: 1rem;
    padding-left: 0px;
  }
  .nav__link .dropdown__menu {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }

  .nav__link:hover .dropdown__menu {
    opacity: 1;
    max-height: 1000px;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
  }

  .dropdown__menu a {
    color: white !important;
  }
  .dropdown__link:hover {
    background-color: #2c323b;
  }

  .dropdown {
    height: 100%;
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    color: #70757d !important;
    margin-right: -15px;
    font-size: 14px;
    font-weight: 400;
    display: none;
    position: absolute;
    background-color: #f9fafc;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 7px;
    right: 20px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    transform: translateY(15px);
  }
  .dropdown-content.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }

  .dropdown-content a {
    color: #70757d !important;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: black;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .submenu__container {
    position: static;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }

  .submenu__container.show-submenu {
    max-height: 1000px;
    transition: max-height 0.5s ease-in;
  }

  .submenu__list li {
    padding: 0.5rem 0.5rem;

    flex-direction: column;
    align-items: stretch;
  }

  .submenu__link {
    padding: 0.5rem 1.3rem;
    color: #fff;
    text-decoration: none;
  }

  .submenu__list :hover {
    background-color: #424e5e;
    color: #ddd;
  }
}

.nav__link {
  height: 100%;
  color: white;
  text-decoration: none;
  padding: 1.15rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  cursor: pointer;
}

/* show menu */

.show-menu {
  opacity: 1;
  top: 4.35rem;
  pointer-events: initial;
}

/* show icon */
.show-icon .nav__burger {
  opacity: 1;
  transform: rotate(90deg);
}
.show-icon .nav__close {
  opacity: 1;
  transform: rotate(90deg);
}

/* Dropdown */

.dropdown__item {
  cursor: pointer;
}

.submenu__arrow {
  font-size: 1rem;
  font-weight: initial;
  transition: transform 0.4s;
}

.dropdown__link {
  text-decoration: none;
  color: white;

  padding: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  /* font-weight: var(--font-semi-bold); */
  transition: 0.3s;
}

.dropdown__menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-out, opacity 0.7s ease, transform 0.7s ease;
}

.dropdown__item:hover .dropdown__menu {
  max-height: 1000px;
  transition: max-height 0.7s ease-in, opacity 0.7s ease, transform 0.7s ease;
}

/* Rotate dropdown icon */
.submenu__arrow.active {
  transform: rotate(180deg);
}

/* Breakpoints  */

@media screen and (max-width: 340px) {
  .container {
    margin-inline: 1rem;
  }
  .nav__link {
    padding-inline: 0.7rem;
  }
}
@media screen and (min-width: 1250px) {
  .nav__link {
    padding: 0.8rem 1rem !important;
  }
}

/* For large screen */
@media screen and (min-width: 1100px) {
  ul {
    padding-left: 0px !important;
  }

  .container {
    margin-inline: auto;
  }
  .user__menu {
    z-index: 100;
    position: absolute;
    width: 180px;
    top: 5rem;
    background-color: white;
    /* ttop; */
  }
  .nav__toggle {
    display: none;
  }

  .nav__list {
    margin-left: -12px;
    background-color: #313a46;
    font-size: 12px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .nav__link {
    height: 100%;
    padding: 0.8rem 0.6rem;
    justify-content: initial;
    column-gap: 0.25rem;
  }
  .nav__link i {
    font-size: 14px;
  }
  .nav__link.active {
    background-color: #424e5e;
    color: white;
  }
  .dropdown__menu ul {
    padding: 0;
    margin: 0;
  }
  .dropdown__menu a {
    font-weight: 500; /**Dropdown font weight */
    color: #70757d !important;
  }
  .dropdown__item {
    position: relative;
    height: 3.5rem;
  }

  .dropdown__menu {
    min-width: 12rem;
    overflow: hidden;
    /* margin-top: 14px; */
    max-height: initial;
    position: absolute;
    left: 0;
    opacity: 0;
    pointer-events: none;

    transition: all 1s ease;

    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    transition: max-height 0.6s ease-out, opacity 0.6s ease, transform 0.6s ease;
  }

  .dropdown__link {
    font-size: 12px;
    color: black;
    padding: 0.5rem 0.9rem !important;
    background-color: white;
  }

  /* show Dropdown menu */

  .dropdown__item:hover .dropdown__menu {
    opacity: 1;
    pointer-events: initial;
    transition: max-height 0.6s ease-in, opacity 0.6s ease, transform 0.6s ease;
  }
  .dropdown__link:hover {
    background-color: whitesmoke;
  }

  /* user Menu  */
  .dropdown {
    height: 100%;
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    /* top: 3rem; */
    color: #70757d !important;
    font-size: 14px;
    font-weight: 500;
    margin-right: -15px;

    display: none;
    position: absolute;
    background-color: #f9fafc;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9;
    border-radius: 3px;
    right: 20px;
    transition: opacity 0.3s ease, transform 0.3s ease !important;
    opacity: 0;
    transform: translateY(15px);
  }
  .dropdown-content.show {
    margin-right: -15px;

    display: block;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease !important;
  }

  .dropdown-content a {
    color: #70757d !important;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    font: black;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  /** User Menu for small Scree */

  /* show Logout  */

  .nav__menu {
    position: relative;
    background-color: #313a46;
  }

  .submenu__container {
    background-color: #424e5e;
    display: flex;
    justify-content: flex-start;
    padding-left: 100px;
    position: absolute;
    top: 100%;

    left: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }

  .submenu__container.show-submenu {
    max-height: 1000px; /* Adjust as needed */
    transition: max-height 0.5s ease-in;
  }

  .submenu__list {
    display: flex;
    list-style-type: none;
    margin: 0;
  }

  .submenu__link {
    display: block;

    padding: 0.2rem 0.9rem;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  .submenu__link:hover {
    color: #ddd;
  }

  .submenu__link.active {
    background-color: #5c6d83;
    color: whtie;
  }
}
</style>
