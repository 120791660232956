import { ref, readonly } from "vue";
import axios from "axios";
import { readReceiptRoutes as staticReadReceiptRoutes } from "../../router/router";

const readReceiptRoutes = ref([]);
const isDRMenuFetched = ref(false);
const isLoading = ref(false);

let DRmenuDataPromise = null;

export function useDRMenuStore() {
  const fetchDigiMenuData = async () => {
    // if (isDRMenuFetched.value) return readReceiptRoutes.value;
    if (DRmenuDataPromise) return DRmenuDataPromise;

    isLoading.value = true;
    // console.log("Fetching menu data...");

    DRmenuDataPromise = axios
      .get("/auth-service/ui/component/list/digital-receipt")
      .then((response) => {
        // console.log("Received DRmenu data:", response.data);
        const data = response.data;
        if (data.status === "success") {
          // console.log("Transforming DRmenu items...");
          readReceiptRoutes.value = transformDRMenuItems(data.menus);
          isDRMenuFetched.value = true;
          // console.log("Transformed DRmenu items:", readReceiptRoutes.value);
          return readReceiptRoutes.value;
        } else {
          throw new Error("Failed to fetch DRmenu data");
        }
      })
      .catch((error) => {
        // console.error("Error fetching DRmenu data:", error);
        throw error;
      })
      .finally(() => {
        isLoading.value = false;
        DRmenuDataPromise = null;
      });

    return DRmenuDataPromise;
  };

  // const transformDRMenuItems = (menus) => {
  //   return menus.map((menu) => {
  //     const existingMenuItem = staticReadReceiptRoutes.find(
  //       (item) => item.label === menu.name
  //     );
  //     return {
  //       label: menu.name,
  //       icon: existingMenuItem?.icon || "ri-file-list-2-line ri-lg",
  //       children: menu.subMenus?.map((subMenu) => ({
  //         label: subMenu.name,
  //         path: `/rdep-receipt/${menu.name
  //           .replace(/\s/g, "")
  //           .toLowerCase()}/${subMenu.name.replace(/\s/g, "").toLowerCase()}`,
  //         isComponent: subMenu.isComponent,
  //         icon:
  //           staticReadReceiptRoutes.find((item) => item.label === subMenu.name)
  //             ?.icon || "ri-file-list-2-line ri-lg",
  //       })),
  //       path: menu.isComponent
  //         ? `/rdep-receipt/${menu.name.replace(/\s/g, "").toLowerCase()}`
  //         : undefined,
  //     };
  //   });
  // };

  const transformDRMenuItems = (menus) => {
    return menus.map((menu) => {
      const existingMenuItem = staticReadReceiptRoutes.find(
        (item) => item.label === menu.name
      );
      return {
        label: menu.name,
        icon: existingMenuItem?.icon || "ri-file-list-2-line ri-lg",
        children: menu.subMenus?.map((subMenu) => ({
          label: subMenu.name,
          path: `/rdep-receipt/${menu.name
            .replace(/\s/g, "")
            .toLowerCase()}/${subMenu.name.replace(/\s/g, "").toLowerCase()}`,
          isComponent: subMenu.isComponent,
          icon:
            staticReadReceiptRoutes.find((item) => item.label === subMenu.name)
              ?.icon || "ri-file-list-2-line ri-lg",
        })),
        path: menu.isComponent
          ? menu.path ||
            `/rdep-receipt/${menu.name.replace(/\s/g, "").toLowerCase()}`
          : undefined,
      };
    });
  };

  return {
    readReceiptRoutes: readonly(readReceiptRoutes),
    isDRMenuFetched: readonly(isDRMenuFetched),
    isLoading: readonly(isLoading),
    fetchDigiMenuData,
  };
}
